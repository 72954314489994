.home {
  max-width: 820px;
  margin: auto;
}
.home-grid {
  width: 100%;
}
.home-grid-item {
  max-width: 380px;
  margin: 18px auto;
  width: 100%;
}
.home-loading {
  margin: 220px -60px;
  width: 120px;
  left: 50%;
  position: absolute;
  height: 120px;
}
