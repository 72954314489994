.bio {
  margin: 22px auto;
  max-width: 750px;
}
.bio-grid {
  width: 100%;
}
.bio-button {
  width: 165px;
}
.bio-img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.bio-text {
  width: 100px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
}
.bio-card {
  max-width: 750px;
  height: 1350px;
  margin: 22px auto;
}
.bio-frame {
  width: 100%;
  height: 100%;
}
