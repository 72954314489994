body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

.boxes {
	z-index: -1; 
	position: fixed; 
	display: block;
}

a {
  text-decoration: none;
  color: blue;
}

.nav {
  height: 64px;
}
.nav-logo {
  height: 50px;
  width: 50px;
  margin-right: 20px;
}
.nav-button {
  height: 64px;
  background-color: #9e9e9e;
}
.nav-text {
  color: #fff;
  font-size:18px;
}

.content-source {
  width: 100%;
  font-size: 12px;
  display: block;
}
.content-img {
  height: 22px;
  width: 22px;
  margin-left: 6px;
}
.content-date {
  font-size: 12px;
  float: right;
  line-height: 38px;
}
.twitter-avatar {
	height: 28px;
	width: 28px;
	vertical-align: top;
	float: left;
	padding: 5px;
}
.twitter-media {
	margin-top: 10px;
}

.content-media {
  width: 100%;
}

.home {
  max-width: 820px;
  margin: auto;
}
.home-grid {
  width: 100%;
}
.home-grid-item {
  max-width: 380px;
  margin: 18px auto;
  width: 100%;
}
.home-loading {
  margin: 220px -60px;
  width: 120px;
  left: 50%;
  position: absolute;
  height: 120px;
}

.project-card {
  max-width: 700px;
  margin: 22px auto;
}
.project-title {
  font-size: 24px;
  line-height: 28px;
  float: left;
}
.project-date {
  font-size: 12px;
  float: right;
}
.project-text {
  margin: auto;
}
.project-img {
  width: 100%;
}
.bio {
  margin: 22px auto;
  max-width: 750px;
}
.bio-grid {
  width: 100%;
}
.bio-button {
  width: 165px;
}
.bio-img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.bio-text {
  width: 100px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
}
.bio-card {
  max-width: 750px;
  height: 1350px;
  margin: 22px auto;
}
.bio-frame {
  width: 100%;
  height: 100%;
}

.app {
  margin: 8px;
}

