.content-source {
  width: 100%;
  font-size: 12px;
  display: block;
}
.content-img {
  height: 22px;
  width: 22px;
  margin-left: 6px;
}
.content-date {
  font-size: 12px;
  float: right;
  line-height: 38px;
}
.twitter-avatar {
	height: 28px;
	width: 28px;
	vertical-align: top;
	float: left;
	padding: 5px;
}
.twitter-media {
	margin-top: 10px;
}
